import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root } from "./routes/Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        lazy: async () => {
          const { CollectionView, loader } = await import("./routes/CollectionLog");
          return { loader, Component: CollectionView };
        },
      },
      {
        path: "/transmogs/:collectionId",
        lazy: async () => {
          const { CollectionView, loader } = await import("./routes/CollectionLog");
          return { loader, Component: CollectionView };
        },
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Disables console logging in production
if (process.env.NODE_ENV !== "development") {
  console.log = function () {
    return;
  };
}
